import { Interview } from "@/interfaces/responses/interviews/interviews";
import store from "@/store";
import { GET_ROLE_BY_NAME } from "@/store/modules/common/constants";
import i18n from "@/i18n";

export function get_interview_status_class(
  status: Interview.Status | string
): string {
  if (status === Interview.Status.INVITE_SENT) return "invite-sent-status";
  else if (status === Interview.Status.TechnicalInterview)
    return "started-status";
  else if (status === Interview.Status.CANCELLED) return "cancelled-status";
  else if (
    status === Interview.Status.TechnicalInterview ||
    status === Interview.Status.HrInterview
  )
    return "in-process-status";
  else return "finished-status";
}

export function get_interview_status_msg(
  status: Interview.Status | string,
  role: number
): string {
  const candidate_role =
    store.getters[`common/${GET_ROLE_BY_NAME}`]("individual");
  if (status === Interview.Status.INVITE_SENT && candidate_role.id === role)
    return i18n.t("shared.interview.invite-received").toString();
  else if (
    status === Interview.Status.INVITE_SENT &&
    candidate_role.id !== role
  )
    return i18n.t("shared.interview.invite-send").toString();
  else if (
    status === Interview.Status.TechnicalInterview ||
    status === Interview.Status.HrInterview
  )
    return i18n.t("shared.in-progress").toString();
  else if (
    status === Interview.Status.TechnicalInterview ||
    status === Interview.Status.HrInterview
  )
    return i18n.t("shared.in-progress").toString();
  else if (status === Interview.Status.CANCELLED)
    return i18n.t("shared.cancelled").toString();
  else return i18n.t("shared.interview.completed").toString();
}
